<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('@/assets/logo.png')"
          class="my-3"
          contain
          height="250"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          {{ labels.welcome }} {{ labels.title }}!
        </h1>

        <p class="subheading font-weight-regular">
          {{ labels.contact }}
          <a
            :href="href"
            target="_blank"
          >{{ labels.devops }}</a>
        </p>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          {{ labels.next }}
        </h2>

        <p>
          {{ labels.help }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { labels } from '@/assets/texts.json';

export default {
  name: 'Home',
  data: () => ({
    href: 'mailto:devops@rfranco.com',
    labels,
  }),
};
</script>
